








































import { Component, Inject as VueInject, Mixins, Prop, Ref } from 'vue-property-decorator'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../../support'
import {
  FloatingOrderRenewalResolvedModuleData
} from '../../../../modules/FloatingOrderRenewal/FloatingOrderRenewal.contracts'

import { BaseCartMixin } from '../../../checkout/shared/mixins/base-cart.mixin'
import { Loader } from '../../../shared/molecules/Loader'
import { RouteName as CheckoutRoutes } from '../../../checkout/routes'

import { OrderProductItem } from '../../molecules/OrderProductItem'
import { IOrderRenewalService, OrderRenewalServiceKey } from '../../services/order-renewal'
import { ToastMixin } from '../../../shared'
import { RouteNames } from '../../routes'
import { RawLocation } from 'vue-router'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<FloatingOrderRenewal>({
  name: 'FloatingOrderRenewal',
  components: { Loader, OrderProductItem },
  updated (): void {
    this.listenOnMouseEvents()
  },
  beforeDestroy (): void {
    removeEventListener('mouseenter', this.onMouseEnter)
    removeEventListener('mouseleave', this.onMouseLeave)
  }
})
export class FloatingOrderRenewal extends Mixins(BaseCartMixin, ToastMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(OrderRenewalServiceKey)
  protected readonly orderRenewalService?: IOrderRenewalService

  @Prop({ type: Object, required: true, default: null })
  public orderData!: FloatingOrderRenewalResolvedModuleData | null

  @Prop({ type: Boolean, required: false, default: false })
  public isLoading?: boolean

  @Ref('floatingBadge')
  public floatingBadgeRef?: HTMLElement

  public isOpen: boolean = false
  public isRenewalPending: boolean = false

  public get hasOrderProducts (): boolean {
    return !!this.orderData && !!this.orderData.orderDisplayData && !!this.orderData.orderDisplayData.products && this.orderData.orderDisplayData.products.length > 0
  }

  public get hasProductsTotalCost (): boolean {
    return !!this.orderData && !!this.orderData.orderDisplayData && !!this.orderData.orderDisplayData.productsTotalCost
  }

  public get ordersLink (): RawLocation {
    return { name: `orders.${RouteNames.History}` }
  }

  public onBadgeClick (): void {
    if (!this.isMobile() || this.isLoading) {
      return
    }
    this.isOpen = !this.isOpen
  }

  /**
   * Renews order by adding it to the cart
   * @see orderRenewalService
   */
  public async renewOrder () {
    if (!this.orderRenewalService || !this.orderData || !this.orderData.orderToBeRenewed) {
      return
    }

    this.isRenewalPending = true

    try {
      const renewedCart = await this.orderRenewalService.renewCustomerOrder(this.orderData.orderToBeRenewed)

      if (renewedCart) {
        this.refreshCart(renewedCart)
        await this.$router.push({ name: `checkout.${CheckoutRoutes.Cart}` })
      }
    } catch (e) {
      logger(e)
      this.showToast((e as Error).message, 'warning')
    } finally {
      this.isRenewalPending = false
    }
  }

  protected listenOnMouseEvents () {
    if (this.isMobile() || this.isLoading) {
      return
    }

    if (this.floatingBadgeRef) {
      this.floatingBadgeRef.addEventListener('mouseenter', this.onMouseEnter)
      this.floatingBadgeRef.addEventListener('mouseleave', this.onMouseLeave)
    }
  }

  private onMouseEnter (): void {
    this.isOpen = true
  }

  private onMouseLeave (): void {
    this.isOpen = false
  }
}

export default FloatingOrderRenewal
