





import { Component } from 'vue-property-decorator'

import { Inject, logger } from '../../../support'
import { IOrdersRepository, OrdersRepositoryType } from '../../../front/orders/contracts/repository'
import {
  IProductsRepository,
  ProductsRepositoryType
} from '../../../front/products/contracts/repositories'
import { OrderData } from '../../../contexts/orders/contracts/orders'
import {
  OrderProductItem,
  OrderProductItemProps
} from '../../../front/orders/molecules/OrderProductItem'

import { AbstractModuleUi } from '../../abstract/ui'

import {
  FloatingOrderData,
  FloatingOrderRenewalModule,
  FloatingOrderRenewalResolvedModuleData, PartiallyComposedOrderProduct
} from '../FloatingOrderRenewal.contracts'

import {
  FloatingOrderRenewal
} from '../../../front/orders/organisms/FloatingOrderRenewal/FloatingOrderRenewal.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<FloatingOrderRenewalUi>({
  name: 'FloatingOrderRenewalUi',
  components: { FloatingOrderRenewal, OrderProductItem },
  async created (): Promise<void> {
    await this.composeRenewalModuleData()
  }
})
export class FloatingOrderRenewalUi extends AbstractModuleUi<FloatingOrderRenewalModule> {
  @Inject(OrdersRepositoryType)
  protected readonly ordersRepository!: IOrdersRepository

  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  public orderData: FloatingOrderRenewalResolvedModuleData | null = null
  public isLoading: boolean = false

  protected async composeRenewalModuleData (): Promise<void> {
    this.isLoading = true

    this.orderData = {
      position: this.content.position,
      offsetTop: this.content.offsetTop,
      orderToBeRenewed: null,
      orderDisplayData: null
    }

    try {
      const customerOrders = await this.ordersRepository.loadOrders(1, 1)

      if (customerOrders.orders.length === 0) {
        return
      }

      this.orderData = {
        ...this.orderData,
        orderToBeRenewed: customerOrders.orders[0]
      }

      const productsCost = FloatingOrderRenewalUi.calculateProductsCost(customerOrders.orders)
      const lastOrder = FloatingOrderRenewalUi.getLastOrder(customerOrders.orders)
      const productsSkus = FloatingOrderRenewalUi.getOrderProductsSkus(lastOrder)

      const partiallyComposedProduct = await this.resolveOrderProducts(productsSkus)
      if (!partiallyComposedProduct) {
        return
      }

      const candidateOrderProductItems = FloatingOrderRenewalUi.composeCandidateOrderProductItems(partiallyComposedProduct, lastOrder)

      this.orderData = {
        ...this.orderData,
        orderDisplayData: FloatingOrderRenewalUi.getDisplayOrderData(
          lastOrder.id.toString(),
          candidateOrderProductItems,
          productsCost
        )
      }
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.isLoading = false
    }
  }

  private async resolveOrderProducts (
    productsSkus: Array<string>
  ): Promise<Array<PartiallyComposedOrderProduct> | null> {
    try {
      const resolvedProducts = await this.productsRepository.loadProductsBySkus(productsSkus)

      return resolvedProducts.map((product) => {
        const variant = Object.values(product.variants)[0]
        return {
          image: variant.images[0],
          name: variant.name,
          sku: variant.sku
        }
      })
    } catch (e) {
      logger(e)
      return null
    }
  }

  private static getDisplayOrderData (id: string, candidateOrderProductItems: Array<OrderProductItemProps>, productsTotalCost: number): FloatingOrderData {
    return {
      id,
      products: candidateOrderProductItems,
      productsTotalCost: productsTotalCost
    }
  }

  private static calculateProductsCost (orders: Array<OrderData>): number {
    return orders.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.productsCost
    }, 0)
  }

  private static composeCandidateOrderProductItems (
    partiallyComposedProduct: Array<PartiallyComposedOrderProduct>,
    lastOrder: OrderData
  ): Array<OrderProductItemProps> {
    const candidateOrderProductItems: Array<OrderProductItemProps> = []
    for (const partialData of partiallyComposedProduct) {
      for (const orderProduct of lastOrder.products) {
        if (partialData.sku === orderProduct.sku) {
          candidateOrderProductItems.push({
            ...partialData,
            price: orderProduct.price,
            quantity: orderProduct.quantity
          })
        }
      }
    }
    return candidateOrderProductItems
  }

  private static getOrderProductsSkus (order: OrderData): Array<string> {
    return order.products.map((product) => product.sku)
  }

  private static getLastOrder (orders: Array<OrderData>): OrderData {
    return orders[0]
  }
}

export default FloatingOrderRenewalUi
