


















import { Component, Prop, Vue } from 'vue-property-decorator'

import { toImageProps } from '../../../shared/support'

import { OrderProductItemProps } from './OrderProductItem.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<OrderProductItem>({
  name: 'OrderProductItem'
})
export class OrderProductItem extends Vue {
  @Prop({ type: Object, required: false })
  public readonly image?: OrderProductItemProps['image']

  @Prop({ type: String, required: true })
  public readonly name!: OrderProductItemProps['name']

  @Prop({ type: Object, required: true })
  public readonly price!: OrderProductItemProps['price']

  @Prop({ type: Number, required: true })
  public readonly quantity!: OrderProductItemProps['quantity']

  @Prop({ type: String, required: false })
  public readonly sku!: OrderProductItemProps['sku']

  public toImageProps = toImageProps
}

export default OrderProductItem
